let files = {
  files: {
    title: 'Arquivos',
    reporting: 'Relatórios',
    market_news: 'Informativos de mercado',
    contracts: 'Contratos',
    contracts_energy: 'Energia',
    contracts_distributors: 'Distribuidora',
    breakDown_costs: 'BreakDown de custos',
    publication_date: 'Data de publicação.',
    file_name: 'Nome do arquivo',
    actions: 'Ação',
    not_found_result: 'Não achamos resultados para',
    invoices: 'Faturas',
    studies: 'Estudos',
    others: 'Outros'
  }
};

export default files;
