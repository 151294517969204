let navbar = {
  navbar: {
    greeting: 'Welcome',
    perfil: 'Profile',
    configurations: 'Settings',
    logout: 'Logout'
  }
};

export default navbar;
