import glossary from './en_us/glossary';
import auth from './en_us/auth';
import navbar from './en_us/navbar';
import realTime from './en_us/realTime';
import consumptionCCEE from './en_us/consumptionCCEE';
import regulated_market from './en_us/regulated_market';
import free_market from './en_us/free_market';
import files from './en_us/files';
import profile from './en_us/profile';
import management from './en_us/management';

const en_us_translation = {
  ...glossary,
  ...auth,
  ...navbar,
  ...realTime,
  ...consumptionCCEE,
  ...regulated_market,
  ...free_market,
  ...files,
  ...profile,
  ...management
};

export var en_us = en_us_translation;
