let management = {
  management: {
    title: 'Gestão',
    associate_user: {
      title: 'Associar Usuário',
      user_name: 'Usuário',
      login_user: 'Associar'
    }
  }
};

export default management;
