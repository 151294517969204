let free_market = {
  free_market: {
    title: 'Free market',
    apply: 'Apply',
    prices: 'Prices',
    study: 'Study',
    submarket: 'Submarket',
    current_month: 'Current Month',
    all: 'All',
    value: 'Value',
    values: 'values',
    monthly: 'Monthly',
    agile: 'Agio',
    conventional: 'Conventional',
    projection: 'Projection',
    disclaimerPriceTitle: 'Future Price Curves',
    disclaimerPrice:
      'The futures price curves come from the market marking made daily by Libra Energia. These prices do not necessarily represent trades carried out and may be based on market expectations of agents or on indicative purchase and sale values (bid/offer). Under no circumstances should the prices presented be interpreted as offers to buy or sell energy between Libra Energia and its customers.'
  }
};

export default free_market;
