let chartDemandaAtiva = {
  chartDemandaAtiva: {
    tip: 'Ponta',
    off_the_tip: 'Fora de ponta',
    contract_tip: 'Contrato Ponta',
    tip_tolerance: 'Tolerância Ponta',
    off_peak_contract: 'Contrato Fora de Ponta',
    off_peak_tolerance: 'Tolerância Fora de Ponta'
  }
};

export default chartDemandaAtiva;
