import glossary from './pt_br/glossary';
import auth from './pt_br/auth';
import navbar from './pt_br/navbar';
import realTime from './pt_br/realTime';
import consumptionCCEE from './pt_br/consumptionCCEE';
import regulated_market from './pt_br/regulated_market';
import free_market from './pt_br/free_market';
import files from './pt_br/files';
import profile from './pt_br/profile';
import chartConsumo from './pt_br/chartConsumo';
import chartDemandaAtiva from './pt_br/chartDemandaAtiva';
import management from './pt_br/management';

const pt_br_translation = {
  ...glossary,
  ...auth,
  ...navbar,
  ...realTime,
  ...consumptionCCEE,
  ...regulated_market,
  ...free_market,
  ...files,
  ...profile,
  ...chartConsumo,
  ...chartDemandaAtiva,
  ...management
};

export var pt_br = pt_br_translation;
