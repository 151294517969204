let consumptionCCEE = {
  consumptionCCEE: {
    title: 'Consumo - CCEE',
    name: 'Nome',
    open: 'Abrir',
    consumption: 'Consumo - CCEE',
    ponto_medicao: 'PONTO DE MEDIÇÃO: ',
    month: 'MÊS: ',
    load: 'Carregar',
    timeMaxTip: 'Hora Max. Ponta',
    max_time_off_peak: 'Hora Max. Fora de Ponta',
    accomplished: 'Realizado',
    simulated: 'Simulado',
    total: 'TOTAL',
    graphicType: 'Tipo de Gráfico',
    disclaimerSimulatedTitle: 'Metodologia de Simulação',
    disclaimerSimulated:
      'A ferramenta de simulação utilizada para estimar os valores de consumo nos dias faltantes é baseada nos registros realizados nos mesmos horários e dias das semanas anteriores do mês de análise. Sendo assim, para o    cálculo das estimativas é necessário que existam ao menos 7    dias de histórico dentro deste mesmo mês. A análise distingue apenas dias de semana e fins de semana, ou seja, eventuais feriados, nacionais ou regionais, tem seu consumo estimado com base nos mesmos dias das semanas anteriores.'
  }
};

export default consumptionCCEE;
