/**
 * This file was created to have all the configuration of the application
 *
 * Created by : Libra Energia
 * Support : http://www.libraenergia.com.br/
 *
 *
 */

let base22MessageStyle =
  'font-size:21px; font-weight:200; letter-spacing:0.2em; line-height:1.4em; font-family:helvetica,arial; color:#009B3A;';

console.log('%cLibra Energia', base22MessageStyle);

class Config {
  constructor() {
    this.API_URL = 'https://api-gateway.libraenergia.systems';
    this.CONSUMO_URL =
      'https://www.smartenergy.com.br/Relatorios/Relat_Consumo';
    this.DEMANDA_ATIVA_URL =
      'https://www.smartenergy.com.br/Relatorios/Relat_Dem_Ativa';
  }
}

export default Config;
