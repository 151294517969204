let realTime = {
  realTime: {
    title: 'Real time - Measurements',
    name: 'Name',
    open: 'Open',
    consumption: 'consumption',
    active_demand: 'active demand'
  }
};

export default realTime;
