const TariffPage = () => import('./TariffPage.vue');
const GroupedTariffPage = () => import('./GroupedTariffPage.vue');

export default [
  {
    path: '/regulated-market/tariff',
    name: 'regulatedMarketTariff',
    component: TariffPage
  },
  {
    path: '/regulated-market/groupedTariff',
    name: 'regulatedMarketGroupedTariff',
    component: GroupedTariffPage
  }
];
