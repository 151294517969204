let regulated_market = {
  regulated_market: {
    title: 'Regulated Market',
    select_horosazonal: 'T.Horosazonal',
    select_voltage_class: 'Voltage class',
    search: 'Search',
    loading: 'Loading...',
    not_found_message: 'There are no fees for this filter',
    charges: 'Charges',
    tariff: 'Tariff',
    tariff_menu: 'Tariff',
    grouped_tariff_menu: 'Tariff - Distributors',
    green: 'GREEN',
    blue: 'BLUE',
    average: 'average',
    off_peak_charges: 'Off-peak charges',
    tip: 'Tip',
    off_the_tip: 'Off the tip',
    companies: 'Served Companies',
    tariff_search: 'Tariff Search'
  }
};

export default regulated_market;
