import Vue from 'vue';
import VueRouter from 'vue-router';
import auth from '@/features/auth/auth.routes';
import glossary from '@/features/glossary/glossary.routes';
import freeMarket from '@/features/freeMarket/freeMarket.routes';
import regulatedMarket from '@/features/regulatedMarket/regulatedMarket.routes';
import archives from '@/features/archives/archives.routes';
import realTime from '@/features/realTime/realTime.routes';
import consumptionCCEE from '@/features/consumptionCCEE/consumptionCCEE.routes';
import profile from '@/features/profile/profile.routes';
import associateUser from '@/features/management/associateUser.routes';

import { authGuard } from '@/features/auth/auth.middleware';

Vue.use(VueRouter);

const routes = [
  ...auth,
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/features/resetPassword/resetPassword.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  },
  {
    path: '*',
    redirect: '404'
  },
  {
    path: '/',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/eventos',
        name: 'Eventos',
        component: () => import('../views/Eventos.vue')
      },
      ...glossary,
      ...freeMarket,
      ...regulatedMarket,
      ...archives,
      ...realTime,
      ...profile,
      ...associateUser,
      ...consumptionCCEE
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(authGuard);

export default router;
