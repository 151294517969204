let realTime = {
  realTime: {
    title: 'Medição',
    name: 'Nome',
    open: 'Abrir',
    consumption: 'Consumo',
    active_demand: 'Demanda',
    total_demand: 'Demanda Total',
    tip: 'PONTA',
    off_peak: 'FORA DE PONTA',
    ponto_medicao: 'PONTO DE MEDIÇÃO: ',
    month: 'MÊS: ',
    load: 'Carregar',
    timeMaxTip: 'Hora Max. Ponta',
    max_time_off_peak: 'Hora Max. Fora de Ponta',
    total: 'TOTAL'
  }
};

export default realTime;
