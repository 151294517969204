const PricePage = () =>
  import(/* webpackChunkName: "PricePage" */ './PricePage.vue');

export default [
  {
    path: '/free-market/prices',
    name: 'freeMarketPrices',
    component: PricePage
  }
];
