let regulated_market = {
  regulated_market: {
    title: 'Mercado regulado',
    select_horosazonal: 'T.Horosazonal',
    select_voltage_class: 'Classe tensão',
    search: 'Pesquisar',
    not_found_message: 'Não há resultado para esse filtro.',
    loading: 'Carregando...',
    charges: 'TE e TUSD Encargos (R$/MWh)',
    tariff: 'Tarifa TUSD (R$/kW)',
    tariff_menu: 'Tarifa',
    grouped_tariff_menu: 'Tarifas - Distribuidoras',
    green: 'VERDE',
    green_ape: 'VERDE APE',
    blue: 'AZUL',
    blue_ape: 'AZUL APE',
    average: 'Média',
    off_peak_charges: 'Encargos fora de ponta',
    tip: 'Ponta',
    off_the_tip: 'Fora de ponta',
    companies: 'Unidades Atendidas',
    tariff_search: 'Pesquisa de Tarifas'
  }
};

export default regulated_market;
