import VueToast from 'vue-toast-notification';
import VueI18n from 'vue-i18n';
import Config from './config/config';
import router from './router';
import store from './store';
import axios from 'axios';
import Vue from 'vue';
import App from './App.vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { messages } from './lang/lang';
import { mapState } from 'vuex';

import '@/design/index.scss';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      Vue.$toast.info('Token expirado, Faça o login novamente.', {
        position: 'top-right',
        duration: 5000
      });
      store.dispatch('auth/logOut');
      setTimeout(() => {
        return router.push({ path: '/auth/logout' });
      }, 4000);
    }
  }
);

Vue.config.productionTip = false;
Vue.prototype.CONFIG = new Config();

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueI18n);

mapState({
  lang: (state) => state.lang
});

store.state.currentLanguage = localStorage.currentLanguage
  ? localStorage.currentLanguage
  : 'pt_br';

export const i18n = new VueI18n({
  locale: store.state.currentLanguage,
  fallbackLocale: 'pt_br',
  messages
});
store.state.languages = Object.keys(messages);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
